<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Permisos puestos</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( puestos  , 'PERMISOS_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

	  		    <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="8" lg="5">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="puestos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
    >
      <v-card>
      	<v-card-title class="text-subtitle-1">
      	  Selecciona las opciones para el puesto de {{ puesto }}
      	</v-card-title>
      	<v-card-text class="black--text">
	        <v-row>
	        	<v-col cols="12" v-for="(menu, i) in menuDesglose" :key="i">
	        		<v-subheader class="ml-0 pl-0 text-subtitle-1 black--text">
	        			<v-icon color="primary" left small v-text="menu.action"></v-icon>{{ menu.title }}
	        		</v-subheader>

	        		<!-- RECORREMOS TODOS LOS ITEMS ( ACTIONS Y SUBMENUS ) -->
	        		<v-row>
	        			<v-col cols="12" md="4" lg="3" v-for="( items, i ) in menu.items" :key="i" class="pb-0" v-if="!items.items">
	        				<v-checkbox
	        					class="pl-3 pt-0"
							      v-model="items.seleccionado"
							      :label="items.title"
							      :value="items.idactions"
							      hide-details
							      @click="guardar( items )"
							    ></v-checkbox>
	        			</v-col>
	        		</v-row>

	        		<span v-for="( items, i ) in menu.items" :key="i">

	        			<!-- SUBMENUS -->
	        			<span v-if="items.items">
	        				<v-subheader class="text-subtitle-1 black--text mt-4">
			        			<v-icon color="primary" left small>mdi mdi-circle-outline theme--light</v-icon>{{ items.title }}
			        		</v-subheader>

			        		<v-row class="pl-8 mb-4">
			        			<v-col cols="12" md="4" lg="3" v-for="(item, i) in items.items" :key="i" class="pb-0" v-if="items.items">
			        				<v-checkbox
							    			class="pt-0"
									      v-model="item.seleccionado"
									      :label="item.title"
									      :value="item.idactions"
									      hide-details
									      @click="guardar( item )"
									    ></v-checkbox>
			        			</v-col>
			        		</v-row>
	        			</span>
	        		</span>

					    <v-divider class="mt-4"></v-divider>
	        	</v-col>
	        </v-row>
      	</v-card-text>
			  <v-card-actions>
			    <v-btn color="red" dark small rounded @click="dialog = false">Cancelar</v-btn>
			    <v-spacer></v-spacer>
			    <v-btn color="primary" dark small rounded @click="initialize">Guardar</v-btn>
			  </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

	import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },


    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      
      editedIndex: -1,

      editedItem: {
        id_forma_pago:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      defaultItem: {
        id_forma_pago:0,
				forma_pago:'',
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
      },

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      formaspago: [],
      headers: [
        { text: 'ID'               , value: 'idpuesto'       },
        { text: 'Departamento'     , value: 'departamento'   },
        { text: 'Puesto'           , value: 'puesto'         },
        { text: 'Actions'          , value: 'actions', sortable: false },
      ],

      menuDesglose: [],
      puestos:[],
      puesto: null
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Forma de pago' : 'Editar forma de pago'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
      await this.getPuestos()
    },

    methods: {
      initialize () {
      	this.dialog = false
      	this.cargar = true
        this.menuDesglose = []
        return this.$http.get('menu.get').then(response=>{
        	this.menuDesglose = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
      	this.cargar = true
      	this.puestos= []
    		this.$http.get('puestos.all').then(response=>{
          // Hacemos un ciclo para obtener los datos de los departamentos
          this.puestos = response.data
          this.cargar = false
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},

      editItem (item) {
      	this.cargar = true
        this.editedIndex = this.puestos.indexOf(item)
        this.editedItem = Object.assign({}, item)
      	this.$http.get('permisos.puestos.get/' + this.editedItem.idpuesto ).then(response=>{
      		
      		const idactions = response.data.idactions

      		for( const i in this.menuDesglose ){
    				for( const j in this.menuDesglose[i].items ){
    					if( this.menuDesglose[i].items[j].items ){
	      				for( const k in this.menuDesglose[i].items[j].items ){

	      					if(  idactions.includes( this.menuDesglose[i].items[j].items[k].idactions ) ){
		      					this.menuDesglose[i].items[j].items[k]['seleccionado'] = this.menuDesglose[i].items[j].items[k].idactions
		      				}
	      				}
    					}else{
	      				if( idactions.includes(this.menuDesglose[i].items[j].idactions) ){
	      					this.menuDesglose[i].items[j]['seleccionado'] = this.menuDesglose[i].items[j].idactions
	      				}
	      			}
    				}
      		}

        	this.cargar = false
	        this.dialog = true

        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },

      deleteItem (item) {
        this.editedIndex = this.puestos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('formaspago.eliminar/' + this.editedItem.id_forma_pago, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
	        // Lo mandapos por el EP
          this.$http.put('formaspago.update/' + this.editedItem.id_forma_pago, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('formaspago.add', this.editedItem).then(response=>{
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
        this.close()
      },

      guardar( val ){
      	const payload = {
      		...val,
      		idpuesto: this.editedItem.idpuesto
      	}

      	this.$http.post('permisos.puestos.add', payload).then(response=>{
        	// this.initialize()
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
      },
    },
  }
</script>


